var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticClass:"tab-table-layout",attrs:{"data":_vm.versions,"detailed":"","detail-key":"id"},scopedSlots:_vm._u([{key:"detail",fn:function(ref){
var row = ref.row;
return [_c('h1',{staticClass:"is-size-6 mb-2"},[_vm._v(" Change log: ")]),_c('p',{staticClass:"change_log",domProps:{"innerHTML":_vm._s(row.change_log)}})]}}])},[_c('b-table-column',{attrs:{"label":"Name","field":"name","width":"40%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('strong',[_vm._v(" Version tag: "+_vm._s(row.name)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Author","field":"author"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.author)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Environment","field":"environment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.environment)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"","cell-class":"has-text-right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-button',{staticClass:"app-dropdown-btn",attrs:{"size":"is-small"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleActionDropdown(("" + (row.id) + (row.environment)))}}},[_c('b-icon',{staticClass:"app-dropdown-icon",attrs:{"icon":"dots-horizontal","size":"is-size-6"}})],1),_c('b-dropdown',{ref:("versionActionDropdown" + (row.id) + (row.environment)),staticClass:"app-action-dropdown",attrs:{"aria-role":"list","position":"is-bottom-left","append-to-body":""}},[_c('b-dropdown-item',{staticClass:"is-flex is-align-items-center",attrs:{"disabled":!row.is_deployed && !row.deployed_node,"aria-role":"listitem"},on:{"click":function($event){return _vm.confirmRevert(row)}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"backup-restore","type":"is-black","custom-size":"mdi-22px"}}),_vm._v(" Restore this version on development ")],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }